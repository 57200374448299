import React from 'react'
import { navigate } from 'gatsby'

import './auth.scss'
import VerifyPhoneForm from 'components/Auth/SignUp/VerifyPhone'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'

class VerifyPhonePage extends React.Component {
  onSubmit = async (phone_number, verification_code) => {
    try {
      if (!verification_code) {
        await this.props.auth.verifyPhone(phone_number)
      } else {
        await this.props.auth.checkCode(phone_number, verification_code)
        // TODO: Implement proper redirection to previous route
        navigate(`/dashboard/settings`)
      }
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="verify-phone-page">
        <div className="content-wrap">
          <section className="page-title form-title">
            <h2>Verify Phone</h2>
          </section>
          <VerifyPhoneForm onSubmit={this.onSubmit} />
        </div>
      </div>
    )
  }
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false} showBeacon={true}>
    <SEO title="Verify Phone" />
    <VerifyPhonePage location={location} />
  </Layout>
)
