import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import './style.scss'
import { button } from 'utils/styles'
import LoadingDots from 'components/shared/Forms/LoadingDots'

const UploadBtn = styled('a')`
  display: flex;
  flex: 1;
  ${button.purple};
  ${button.big};
`

export default class VerifyPhoneForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      phone_number: '',
      verification_code: '',
      verificationId: '',
      codeSent: false,
      codeVerified: false,
      incorrectCode: false,
      loading: false,
    }
  }

  componentDidMount() {
    console.log(this.props)
  }

  onSubmit = async () => {
    if (!this.form.reportValidity()) {
      return
    }
    let {
      phone_number,
      verification_code,
      codeSent,
      loading,
      incorrectCode,
    } = this.state
    let error
    this.setState({ loading: true })
    if (!codeSent) {
      error = await this.props.onSubmit(phone_number)
      this.setState({ codeSent: true, loading: false })
    } else {
      error = await this.props.onSubmit(phone_number, verification_code)
      if (error) {
        this.setState({ incorrectCode: true, loading: false })
      }
    }
    if (error) {
      console.log(error)
      return this.setState({ error })
    }
    this.setState({ codeVerified: true, loading: false })
  }

  resetVerification = () => {
    this.setState({
      codeSent: false,
      incorrectCode: false,
    })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      phone_number,
      codeSent,
      verification_code,
      incorrectCode,
      loading,
    } = this.state

    return (
      <section className="form-page-container">
        <div className="verification-container">
          <div className="hint-text" style={{ marginBottom: 20 }}>
            {codeSent
              ? 'Verification code sent. Please enter the 4-digit code.'
              : 'This helps lenders and renters know that you are a real person'}
          </div>
          <form ref={el => (this.form = el)}>
            {!codeSent ? (
              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="phone_number">Phone number</label>
                <input
                  type="tel"
                  autoFocus
                  required
                  maxLength="250"
                  name="phone_number"
                  id="phone_number"
                  value={phone_number}
                  onChange={this.onChange}
                />
              </fieldset>
            ) : (
              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="verification_code">Verification Code</label>
                <input
                  autoFocus
                  required
                  maxLength="250"
                  name="verification_code"
                  id="verification_code"
                  value={verification_code}
                  onChange={this.onChange}
                />
              </fieldset>
            )}
          </form>
          {incorrectCode ? (
            <p className="hint-text">
              Incorrect code.{' '}
              <div
                onClick={this.resetVerification}
                style={{ display: 'inline-block', cursor: 'pointer' }}
              >
                <a>Resend code</a>
              </div>
            </p>
          ) : (
            <div />
          )}
          <div className="bottom-btn-container step-1">
            <div>
              <UploadBtn onClick={this.onSubmit}>
                {!codeSent ? 'Continue' : 'Verify'}
              </UploadBtn>
            </div>
          </div>
          <div className="loading-animation">
            <LoadingDots loading={loading} />
          </div>
        </div>
      </section>
    )
  }
}
